// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._tb9lWYkoy_X0WOTs7Ag input{font-size:.875rem}._tb9lWYkoy_X0WOTs7Ag .ZvwrcZW8bEax0WxtxLmm{background:#1d2140 !important;font-size:.875rem}", "",{"version":3,"sources":["webpack://./src/audience/_view/CreateAudienceModal.module.scss"],"names":[],"mappings":"AAGE,4BACE,iBAAA,CAGF,4CACE,6BAAA,CACA,iBAAA","sourcesContent":["@import 'src/__assets/styles/variables.scss';\r\n\r\n.create-audience {\r\n  input {\r\n    font-size: .875rem;\r\n  }\r\n\r\n  .submit {\r\n    background: $primary !important;\r\n    font-size: .875rem;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create-audience": "_tb9lWYkoy_X0WOTs7Ag",
	"submit": "ZvwrcZW8bEax0WxtxLmm"
};
export default ___CSS_LOADER_EXPORT___;
