import OutlookAppModel from "../../_model/OutlookAppModel";
import AaLibUtil from "@sales-drive/audience-library/lib/utility/Util";

class Util {
  public static delay(ms: number): Promise<void> {
    return new Promise((res) => setTimeout(res, ms));
  }

  public static timeOut(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public static getAaImgUrl(uri: string, storageUrl = OutlookAppModel.getInstance().storageUrl): string {
    if (uri.includes('contentFile://')) {
      return AaLibUtil.parseContentFileUri(uri, storageUrl);
    }
    return AaLibUtil.parseAssetToAbsoluteUrl(uri, storageUrl);
  }
}

export default Util;
