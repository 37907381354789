import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import AudienceModel from "../_model/AudienceModel";
import classNames from "classnames";
import styles from './CreateAudienceModal.module.scss';
import languageManager from "../../__lib/language_manager/LanguageManager";
import * as yup from "yup";
import {Formik} from "formik";
import audienceListController from "../_controller/AudienceListController";
import {useTranslation} from "react-i18next";

interface ComponentProps {
    onHide: (newAudience?: AudienceModel) => void;
    recipient: Office.EmailAddressDetails;
}

const schema = yup.object().shape({
  displayName: yup.string().required().min(3),
  email: yup.string().email().required(),
  langCode: yup.string().required(),
});

export default function CreateAudienceModal({onHide, recipient}: ComponentProps) {
    const {t} = useTranslation();
    const audience = new AudienceModel();

    const handleSubmit = async (values: any) => {
      audience.displayName = values.displayName;
      audience.email = values.email;
      audience.langCode = values.langCode;

      const createSuccess = await audienceListController.createEntity(audience);
      if (createSuccess) {
        onHide(audience);
      } else {
        // todo error handling
      }
    };

    return (
      <Modal className={styles["create-audience"]} show={true} size="lg" centered onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{t('CreateAudienceTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small className="lh-1">
            {t('CreateAudienceInfo')}
          </small>

          <Formik
            initialValues={{
              displayName: recipient.displayName,
              email: recipient.emailAddress,
              langCode: languageManager.availableLangCodes[0]
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({handleSubmit, handleChange, values, touched, errors}) => (
              <Form noValidate onSubmit={handleSubmit} className="d-flex flex-column mt-3">
                <Form.Group className="mb-3">
                  <Form.Label className="small">{t('CreateAudienceDisplayName')}</Form.Label>
                  <Form.Control
                    name="displayName"
                    type="text"
                    value={values.displayName}
                    onChange={handleChange}
                    isInvalid={touched.displayName && !!errors.displayName}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.displayName + ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="small">{t('CreateAudienceEmail')}</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.email + ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="small">{t('CreateAudienceLanguage')}</Form.Label>
                  <Form.Select name="langCode" onChange={handleChange}>
                    {languageManager.availableLangCodes.map((langCode) => (
                      <option key={langCode} value={langCode}>{langCode}</option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Button type="submit" className={classNames(styles.submit, "small")}>
                  {t('CreateAudienceSubmit')}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
}
