import { LocalStorageKey } from "../../_model/app.constants";

export enum LoadingStatus {
  ID_ONLY,
  LOADING_META,
  META_LOADED,
  LOADING_BODY,
  BODY_ERROR,
  BODY_LOADED
}

export enum DeltaStatus {
  DELETED,
  UNCHANGED,
  UPDATED,
  NEW
}

export enum EntityType {
  AUDIENCE = "AUDIENCE",
  CONTENT = "CONTENT",
  CONTENT_FILE = "CONTENT_FILE",
  PRESENTATION = "PRESENTATION",
  CONTENT_APP = "CONTENT_APP"
}

export enum ContentType {
  PRESENTATION = "PRESENTATION",
  CONTENT_APP = "CONTENT_APP"
}

export interface IEntityTypeConfig {
  apiPath: string;
  localStorageKey: LocalStorageKey;
  storageFolder: string;
  shorthand: string;
  canBePresented: boolean; //used in EntityActionBox
  canBeTrained: boolean; //used in EntityActionBox
  canBePrepared: boolean; //used in EntityActionBox
  canBeSharedWithAudiences: boolean; //used in EntityActionBox
  canBeCopied: boolean; //used in EntityActionBox
  hasInfo: boolean; //used in EntityActionBox
}


export const ENTITY_CONFIG: { [key in EntityType]: IEntityTypeConfig } = {
  [EntityType.AUDIENCE]: {
    apiPath                  : 'audiences',
    localStorageKey          : LocalStorageKey.AUDIENCES_META,
    storageFolder            : 'audiences',
    shorthand                : "aud",
    canBePresented           : false,
    canBeTrained             : false,
    canBePrepared            : false,
    canBeSharedWithAudiences : false,
    canBeCopied              : false,
    hasInfo                  : true
  },
  [EntityType.CONTENT] : {
    apiPath                  : 'content',
    localStorageKey          : LocalStorageKey.CONTENTS_META,
    storageFolder            : '',
    shorthand                : "cnt",
    canBePresented           : true,
    canBeTrained             : false,
    canBePrepared            : false,
    canBeSharedWithAudiences : true,
    canBeCopied              : true,
    hasInfo                  : true
  },
  [EntityType.PRESENTATION]: {
    apiPath                  : 'presentations',
    localStorageKey          : LocalStorageKey.CONTENTS_META,
    storageFolder            : 'presentations',
    shorthand                : "prs",
    canBePresented           : true,
    canBeTrained             : true,
    canBePrepared            : false,
    canBeSharedWithAudiences : true,
    canBeCopied              : true,
    hasInfo                  : true
  },
  [EntityType.CONTENT_APP] : {
    apiPath                  : 'content-apps',
    localStorageKey          : LocalStorageKey.CONTENTS_META,
    storageFolder            : 'contentApps',
    shorthand                : "app",
    canBePresented           : true,
    canBeTrained             : true,
    canBePrepared            : true,
    canBeSharedWithAudiences : true,
    canBeCopied              : true,
    hasInfo                  : true
  },
  [EntityType.CONTENT_FILE]: {
    apiPath                  : 'content-files',
    localStorageKey          : LocalStorageKey.CONTENTS_META,
    storageFolder            : 'contentFiles',
    shorthand                : "fil",
    canBePresented           : true,
    canBeTrained             : false,
    canBePrepared            : false,
    canBeSharedWithAudiences : true,
    canBeCopied              : false,
    hasInfo                  : true
  },
}
