import React from 'react';
import styles from './SelectAudienceModal.module.scss';
import {Modal} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';

interface ComponentProps {
  recipients: Office.EmailAddressDetails[];
  sharedContent: string;
  onClickRecipient: (recipient: Office.EmailAddressDetails) => void;
  onHide: () => void;
}

export default function SelectAudienceModal({recipients, sharedContent, onClickRecipient, onHide}: ComponentProps) {
  const {t} = useTranslation();

  return (
    <Modal className={styles["select-audience"]} show={true} size="lg" centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('SelectAudienceTitle')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <small className="lh-1">
          <Trans i18nKey="SelectAudienceInfo" values={{ content: sharedContent }}>
            Select the Audience you would like to share your content with. If the selected Audience does not yet exist in <strong>Salesdrive</strong>, you will first be prompted to create it.
          </Trans>
        </small>

        <div className={styles['audience-list']}>

          {recipients.map((recipient, index) => (
            <div
              className={styles['audience']}
              key={`recipient-${index}`}
              onClick={() => onClickRecipient(recipient)}
            >
              <div className="d-flex flex-column">
                <h3>{recipient.displayName}</h3>
                <span>{recipient.emailAddress}</span>
              </div>

              <button
                className="btn btn-sm btn-secondary"
                onClick={() => onClickRecipient(recipient)}
              >
                {t('SelectAudienceBtn')}
              </button>
            </div>
          ))}

        </div>
      </Modal.Body>
    </Modal>
  );
}
