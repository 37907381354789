import {IPresentationBodyDto, IPresentationMetaDto} from "./presentation.dto";
import {IModelDto} from "../../__lib/_model/model.dto";
import ContentModel from "../../content/_model/ContentModel";
import {EntityType} from "../../entity/_model/entity.constants";
import {DtoType} from "../../_model/app.constants";
import {IMultiLangString} from "../../__lib/language_manager/LanguageManager";
import UserModel from "../../user/_model/UserModel";

export default class PresentationModel extends ContentModel implements IPresentationBodyDto, IPresentationMetaDto {
    public entityType: EntityType = EntityType.PRESENTATION;
    public poster: IMultiLangString = {"en": `assetFolder://asf-${UserModel.getInstance().project.identifier}-system-assets/presentation_poster_default.png`};
    public internalName: IMultiLangString = {"en": ""};
    public construct: string = "default";
    public customParamsURI: string = "project://custom_presentation_params#wall";
    public customParams: any = null;

    constructor(dto?: IModelDto) {
        super();
        if (dto) {
            this.mapFromDto(dto);
        }
    }

    public mapToDto(dtoType: DtoType): IModelDto {
        if (dtoType === DtoType.BODY) {
            const dto: IPresentationBodyDto = {
                ID              : this.ID,
                version         : this.version,
                name            : this.name,
                poster          : this.poster,
                internalName    : this.internalName,
                construct       : this.construct,
                customParamsURI : this.customParamsURI,
                customParams    : this.customParams
            };

            if(this.identifier) {
                dto.identifier = this.identifier;
            }

            return dto;
        }

        return super.mapToDto(dtoType);
    }

}
