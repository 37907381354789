import React, { useState } from "react";
import {Form} from "react-bootstrap";
import styles from "./ContentSearch.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import classNames from "classnames";
import ContentModel from "../../_model/ContentModel";
import {useTranslation} from "react-i18next";
import {getMlValue} from "../../../__lib/language_manager/LanguageManager";

interface ComponentProps {
  content: ContentModel[];
  onFilter: (filtered: ContentModel[]) => void;
}

export default function ContentSearch({ content, onFilter }: ComponentProps) {
  const { t } = useTranslation();

  const [allContentCopy] = useState<ContentModel[]>(content);

  const filterContent = (e: React.FormEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value.toLowerCase();

    if (!search || search.trim().length < 3) {
      onFilter(allContentCopy);
    }

    const filtered = allContentCopy.filter((item) => {
      return getMlValue(item.name).toLowerCase().includes(search.toLowerCase());
    });
    onFilter(filtered);
  }

  return (
    <div className={styles["content-search"]}>
      <div className={classNames(styles.wrapper, "shadow-sm")}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faSearch} size="lg" />
        </div>

        <Form.Control
          type="text"
          placeholder={t('SearchContent')}
          className={styles.input}
          onInput={filterContent}
        />
      </div>
    </div>
  )
}
