import {IContentBodyDto, IContentMetaDto} from "./content.dto";
import {EntityType} from "../../entity/_model/entity.constants";
import EntityModel from "../../entity/_model/EntityModel";
import {IMultiLangString} from "../../__lib/language_manager/LanguageManager";

export default class ContentModel extends EntityModel implements IContentBodyDto, IContentMetaDto {
    public entityType: EntityType = EntityType.CONTENT;

    //body
    public name: IMultiLangString = {};
    public poster: any = {};

    //meta
    public priority: number = 0;
}
