import React, {useContext, useEffect, useState} from "react";
import Header from "../../_view/components/header/Header";
import OutlookAppModel from "../../_model/OutlookAppModel";
import {useHistory} from "react-router-dom";
import {RoutingIdentifier} from "../../__lib/plugins/router/router.constants";
import userController from "../../user/_controller/UserController";
import authController from "../_controller/AuthController";
import Util from "../../__lib/util/Util";
import {Alert, Button, Spinner} from "react-bootstrap";
import {AuthContext} from "../../__lib/providers/AuthProvider";
import styles from "./AuthPage.module.scss";
import {Trans, useTranslation} from "react-i18next";
import {AppMode} from '@sales-drive/audience-library/lib/app/_model/app.constants';
import DialogParentMessageReceivedEventArgs = Office.DialogParentMessageReceivedEventArgs;

export default function AuthPage() {
    const history = useHistory();
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const {isValidToken: isAuthenticated, setAccessToken, setRefreshToken, setTokenExpiration} = useContext(AuthContext);
    const [sessionStartError, setSessionStartError] = useState<string|null>(null);

    const appModel = OutlookAppModel.getInstance();

    useEffect(() => {
        Office.onReady((info) => {
            if (info.host === Office.HostType.Outlook) {
                const savedAccessToken = Office.context.roamingSettings.get('accessToken');
                const savedRefreshToken = Office.context.roamingSettings.get('refreshToken');
                const savedExpiration = Office.context.roamingSettings.get('expiration');
                if (savedAccessToken && savedRefreshToken && savedExpiration) {
                    setAccessToken(savedAccessToken);
                    setRefreshToken(savedRefreshToken);
                    setTokenExpiration(savedExpiration);
                }
            }
        });
    }, []);

    // on mount, check if user is authenticated
    useEffect(() => {
        if (isAuthenticated) {
            userController.startRemoteAppUserSession();
            history.push(RoutingIdentifier.CONTENT_OVERVIEW);
        }
        setIsLoading(false);
    }, [isAuthenticated, history]);

    const login = async() => {
        const domain = appModel.cognito.getAppWebDomain();
        const clientId = appModel.cognito.getClientId();

        const currentUrl = window.location.origin;
        const loginUrl = `https://${domain}/login?response_type=code&client_id=${clientId}&redirect_uri=${currentUrl}/login-callback`;

        if ((module as any).hot && !Office.context.ui) {
            appModel.auth.dialog = window.open(loginUrl);
            window.addEventListener('message', async(event) => {
                if (event.data.value && event.data.type === 'auth' && event.origin === currentUrl) {
                    handleAuthResponse({message: event.data.value} as DialogParentMessageReceivedEventArgs);
                }
            });
        } else {
            await Office.onReady(() => {
                Office.context.ui.displayDialogAsync(loginUrl, {width: 40, height: 60, promptBeforeOpen: false}, async(res) => {
                    appModel.auth.dialog = res.value;
                    appModel.auth.dialog.addEventHandler(Office.EventType.DialogMessageReceived, handleAuthResponse);
                });
            });
        }
    }

    const handleAuthResponse = async (arg: DialogParentMessageReceivedEventArgs) => {
        // close dialog
        appModel.auth.dialog.close();

        // retrieve aws code and try to parse
        const code = arg.message;
        const parseObj = await authController.loginUserParseResponse(code);
        if (!parseObj || !parseObj.accessToken) {
            //this.errorMessage = 'User not found.'; // todo set to splash aaUserNotFound
            await Util.timeOut(10000);
            await authController.logout();
            return;
        }

        if (appModel.mode === AppMode.PRODUCTION) {
            Office.context.roamingSettings.set('accessToken', parseObj.accessToken);
            Office.context.roamingSettings.set('refreshToken', parseObj.refreshToken);
            Office.context.roamingSettings.set('expiration', parseObj.expiration);
            Office.context.roamingSettings.saveAsync();
        }

        // save tokens
        setAccessToken(parseObj.accessToken);
        setRefreshToken(parseObj.refreshToken);
        setTokenExpiration(parseObj.expiration);

        if (!await userController.startRemoteAppUserSession()) {
            setSessionStartError('Failed to start a session for the user.'); // todo better error messaging?
        }
    }

    if (isLoading) {
        return (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <Spinner/>
          </div>
        );
    }

    return (
      <div className={styles.root}>
        <Header/>

          {sessionStartError && (
            <Alert variant="danger">{sessionStartError}</Alert>
          )}

          <Button type="button" className={styles['login-btn']} onClick={() => login()}>
            <Trans i18nKey="LoginWithSalesdrive">
              Login with <strong>Salesdrive</strong>
            </Trans>
          </Button>

          <small>{t('FooterText')}</small>
      </div>
    );
}
