// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OAF5pwWhcWo8Px5jPVB1{display:flex;flex-direction:column;align-items:center;padding-top:20px}.OAF5pwWhcWo8Px5jPVB1 .fN4MvEPjBY490VSnE0dk{font-weight:bold;color:#1d2140}", "",{"version":3,"sources":["webpack://./src/_view/components/header/Header.module.scss","webpack://./src/__assets/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CAEA,4CACE,gBAAA,CACA,aCRG","sourcesContent":["@import '../../../__assets/styles/variables.scss';\r\n\r\n.header {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding-top: 20px;\r\n\r\n  .title {\r\n    font-weight: bold;\r\n    color: $primary;\r\n  }\r\n}","$white: #fff;\r\n$black: #000;\r\n$blue: #1d2140;\r\n$martini: #a08e89;\r\n$gold: #c7a258;\r\n$brown: #71601e;\r\n$light-blue: #007BBD;\r\n$pink: #FF7791;\r\n$gray-100: #f5f5f5;\r\n$gray-200: #ebebeb;\r\n$gray-300: #d2d2d2;\r\n$gray-500: #9fadb9;\r\n$gray-600: #8F9CA9;\r\n$gray-900: #2e2e2e;\r\n$green: #57B186;\r\n\r\n$primary: $blue;\r\n$primary-dynamic: var(--primary, $primary);\r\n$secondary: $gray-300;\r\n$info: $martini;\r\n$light: $white;\r\n$dark: $gray-900;\r\n$success: $green;\r\n$body-bg: $white;\r\n$body-color: $dark;\r\n\r\n$color-content-item-presentation: $primary;\r\n$color-content-item-content-file: $secondary;\r\n$color-content-item-content-app: $secondary;\r\n$color-content-item-portal: $gray-500;\r\n$color-content-item-folder: $gray-500;\r\n\r\n$color-image: #689341;\r\n$color-video: deepskyblue;\r\n$color-spreadsheet: green;\r\n$color-text: royalblue;\r\n$color-portable-document: crimson;\r\n$color-presentation: coral;\r\n$color-archive: gold;\r\n$color-ar-model: #b484c7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OAF5pwWhcWo8Px5jPVB1",
	"title": "fN4MvEPjBY490VSnE0dk"
};
export default ___CSS_LOADER_EXPORT___;
