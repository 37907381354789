import {DEFAULT_LANGUAGE} from "@sales-drive/audience-library/lib/app/_model/app.constants";

export type IMultiLangString = { [index: string]: string };

export type IMultiLangValue<T> = { [index: string]: T };

export type IMultiLangChosenValue<T> = { translation: T; langCode: string };

export class LanguageManager {
  public language: string = DEFAULT_LANGUAGE;
  public fallbackLanguage: string = DEFAULT_LANGUAGE;
  public availableLangCodes:string[] = ["en"];

  public init(p_language: string, p_fallbackLanguage: string) {
    this.language = p_language;
    this.fallbackLanguage = p_fallbackLanguage;
  }

  public getTranslationForValue(mlValue?: IMultiLangString): string {
    if (!mlValue) return '';

    // the preferred language is present
    if (mlValue[this.language] !== undefined) {
      return mlValue[this.language];
    }

    // fallback to existing languages, taking priority off the languages into account
    for (let i = 0; i < this.availableLangCodes.length; i += 1) {
      if (mlValue[this.availableLangCodes[i]] !== undefined) {
        return mlValue[this.availableLangCodes[i]];
      }
    }

    // if still no value is found, return the first found in the mlValue object
    for (const langCode in mlValue) {
      if (mlValue.hasOwnProperty(langCode)) {
        return mlValue[langCode];
      }
    }

    return '';
  }
}

const languageManager = new LanguageManager();

export const getMlValue = (mlValue?: IMultiLangString): string => {
  return languageManager.getTranslationForValue(mlValue);
}

export default languageManager;