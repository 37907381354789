import { EntityType } from "../../entity/_model/entity.constants";
import EntityModel from "../../entity/_model/EntityModel";
import { IAudienceBodyDto } from "./audience.dto";
import UserModel from "../../user/_model/UserModel";
import {DtoType} from "../../_model/app.constants";
import {IModelDto} from "../../__lib/_model/model.dto";

export default class AudienceModel extends EntityModel implements IAudienceBodyDto {
  public entityType: EntityType = EntityType.AUDIENCE;
  
  //body
  public displayName: string = "";
  public email: string = "";
  public langCode: string = "en";
  public firstName: string = "";
  public lastName: string = "";
  public companyID: string = "";
  public avatarFileUri: string = `assetFolder://asf-${UserModel.getInstance().project.identifier}-system-assets/audience_avatar_default.png`;
  public referenceCode: string = "";

  //meta
  public portalToken: string = "";

  public mapToDto(p_dtoType: DtoType): IModelDto {
    if (p_dtoType === DtoType.BODY) {
      return {
        ID           : this.ID,
        version      : this.version,
        displayName  : this.displayName,
        email        : this.email,
        langCode     : this.langCode,
        firstName    : this.firstName,
        lastName     : this.lastName,
        companyID    : this.companyID,
        avatarFileUri: this.avatarFileUri,
        referenceCode: this.referenceCode,
        portalToken  : this.portalToken,
      } satisfies IAudienceBodyDto;
    }

    return super.mapToDto(p_dtoType);
  }
}
