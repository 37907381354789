import { EntityType } from "../../../entity/_model/entity.constants";
import ContentModel from "../../_model/ContentModel";
import {FileType} from "./content-file.constants";
import {IModelDto} from "../../../__lib/_model/model.dto";
import {DtoType} from "../../../_model/app.constants";
import {IContentFileBodyDto, IContentFileMetaDto, IFileOutlineDto} from "./content-file.dto";

export default class ContentFileModel extends ContentModel implements IContentFileBodyDto, IContentFileMetaDto {
  public entityType: EntityType = EntityType.CONTENT_FILE;
  public size: number = 0;
  public fileUri: string = "";
  public fileType: FileType = FileType.UNKNOWN;
  public files: IFileOutlineDto[] = [];
  public totalFileSize: number = 0;

  constructor(p_dto?: IModelDto) {
    super();
    if (p_dto) {
      this.mapFromDto(p_dto);
    }
  }

  public mapToDto(p_dtoType: DtoType): IModelDto {
    if (p_dtoType === DtoType.BODY) {
      return {
        ID           : this.ID,
        version      : this.version,
        name         : this.name,
        poster       : this.poster,
        fileUri      : this.fileUri,
        fileType     : this.fileType,
        size         : this.size,
        files        : this.files,
        totalFileSize: this.totalFileSize,
      } satisfies IContentFileBodyDto;
    }

    return super.mapToDto(p_dtoType);
  }
}
