import { IModelDto} from "./model.dto";
import { DtoType } from "../../_model/app.constants";

export default class Model implements IModelDto {
    public mapFromDto(p_dto:IModelDto) {
        for (let key in p_dto) {
            if (p_dto.hasOwnProperty(key)) {
                const value:any = (p_dto as any)[key];
                if (value !== null && value !== undefined) {
                    (this as any)[key] = value;
                }
            }
        }
    }

    //has to be overridden
    // @ts-ignore
    public mapToDto(p_dtoType: DtoType): IModelDto {
        return {};
    }
}
