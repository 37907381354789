import classNames from "classnames";
import React, {useEffect, useState} from "react";

interface ComponentProps {
  className?: string;
  imageUrl: string;
  imageAlt: string;
  loadedCallbackFn: () => void;
  errorCallbackFn?: () => void;
}

export default function AaImage({ className, imageUrl, imageAlt, loadedCallbackFn, errorCallbackFn }: ComponentProps) {
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {
    setImageIsLoaded(false);
  }, [imageUrl]);

  const onLoad = () => {
    setImageIsLoaded(true);

    if (loadedCallbackFn) {
      loadedCallbackFn();
    }
  }

  const onError = () => {
    setImageIsLoaded(false);

    if (errorCallbackFn) {
      errorCallbackFn();
    }
  }

  return (
    <img
      className={classNames(
        "aa-image",
        className,
        { loaded: imageIsLoaded }
      )}
      src={imageUrl}
      onLoad={onLoad}
      onError={onError}
      alt={imageAlt}
    />
  );
}