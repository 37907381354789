import {IModelDto} from "../../__lib/_model/model.dto";
import ContentModel from "../../content/_model/ContentModel";
import {EntityType} from "../../entity/_model/entity.constants";
import {DtoType} from "../../_model/app.constants";
import {IContentAppBodyDto, IContentAppMetaDto} from "./content_app.dto";
import { IMultiLangString } from "../../__lib/language_manager/LanguageManager";

export default class ContentAppModel extends ContentModel implements IContentAppBodyDto, IContentAppMetaDto {
    public entityType: EntityType = EntityType.CONTENT_APP;
    public resourceIdentifier: string = "";
    public canBeSharedWithAudiences: boolean = true;
    public externalLaunchUrl: string = "";

    constructor(dto?: IModelDto) {
        super();
        if (dto) {
            this.mapFromDto(dto);
        }
    }

    public mapToDto(dtoType: DtoType): IModelDto {
        if (dtoType === DtoType.BODY) {
            const dto: IContentAppBodyDto = {
                ID                      : this.ID,
                version                 : this.version,
                name                    : this.name,
                poster                  : this.poster,
                resourceIdentifier      : this.resourceIdentifier,
                canBeSharedWithAudiences: this.canBeSharedWithAudiences,
                externalLaunchUrl       : this.externalLaunchUrl,
            };

            if (this.identifier) {
                dto.identifier = this.identifier;
            }

            return dto;
        }

        return super.mapToDto(dtoType);
    }
}
