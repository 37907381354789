import { IAppUserDto } from "../../_model/app.dto";
import Model from "../../__lib/_model/Model";
import { IUserDto } from "./user.dto";
import { UserOrigin, UserStatus } from "./user.constants";
import { DtoType } from "../../_model/app.constants";
import { IModelDto } from "../../__lib/_model/model.dto";
import ProjectModel from "../../project/_model/ProjectModel";

class UserModel extends Model implements IAppUserDto, IUserDto {
  public ID: number = 0;
  public roleID: number = 0;
  public displayName: string = "";
  public createdDate: Date = new Date();
  public createdByUserID: number = 0;
  public langCode: string = "en";
  public email: string = "";
  public avatarFileUri: string = "";
  public teamID: number = 0;
  public projectID: number = 0;
  public userOrigin: UserOrigin = UserOrigin.STATIC;
  public status: UserStatus = UserStatus.UNVERIFIED;
  public firstName: string = "";
  public lastName: string = "";
  public telNr: string = "";
  public mobileNr: string = "";
  public faxNr: string = "";
  public department: string = "";
  public jobTitle: string = "";
  public address: string = "";
  public website: string = "";
  public signature: string = "";
  public teamName: string = "";
  public roleIdentifier: string = "";

  public sessionID: number = 0;
  public rights: string[] = [];

  public project: ProjectModel = new ProjectModel();

  //---------------------------------
  // Singleton Constructor
  //---------------------------------
  private static _instance: UserModel;

  private constructor() {
    super();
  }

  public static getInstance(): UserModel {
    if (!this._instance) {
      this._instance = new UserModel();
    }
    return this._instance;
  }

  //untill typescript has proper reflection, we will have to do it this way:
  public mapToDto(p_dtoType: DtoType): IModelDto {
    if (p_dtoType === DtoType.BODY) {
      const dto: IUserDto = {
        ID: this.ID,
        displayName: this.displayName,
        teamID: this.teamID,
        roleID: this.roleID,
        langCode: this.langCode,
        firstName: this.firstName,
        lastName: this.lastName,
        telNr: this.telNr,
        mobileNr: this.mobileNr,
        faxNr: this.faxNr,
        department: this.department,
        jobTitle: this.jobTitle,
        avatarFileUri: this.avatarFileUri,
        address: this.address,
        website: this.website,
        signature: this.signature,
      };
      return dto;
    } else {
      return super.mapToDto(p_dtoType);
    }
  }
}

export default UserModel;
