import {EntityType, ENTITY_CONFIG, IEntityTypeConfig} from "../../entity/_model/entity.constants";
import contentController from "./ContentController";
import ContentModel from "../_model/ContentModel";
import EntityListController from "../../entity/_controller/EntityListController";
import EntityModel from "../../entity/_model/EntityModel";
import {IEntityMetaDto} from "../../entity/_model/entity.dto";
import PresentationModel from "../../presentation/_model/PresentationModel";
import ContentAppModel from "../_model/ContentAppModel";
import ContentFileModel from "../file/_model/ContentFileModel";
import OutlookAppModel from "../../_model/OutlookAppModel";

class ContentListController extends EntityListController<ContentModel> {
  constructor() {
    super(ENTITY_CONFIG.CONTENT, OutlookAppModel.getInstance().content, contentController);
  }

  protected _newEntity(dto: IEntityMetaDto): ContentModel {
    if (dto.entityType === EntityType.PRESENTATION) {
      return new PresentationModel(dto);
    } else if (dto.entityType === EntityType.CONTENT_APP) {
      return new ContentAppModel(dto);
    } else if (dto.entityType === EntityType.CONTENT_FILE) {
      return new ContentFileModel(dto);
    } else {
      return new ContentModel();
    }
  }

  protected _getEntityConfig(entity: EntityModel): IEntityTypeConfig {
    return ENTITY_CONFIG[entity.entityType];
  }
}

export default new ContentListController();
