import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import appUserController from "../user/_controller/UserController";
import authController from "../auth/_controller/AuthController";

class ApiManager {
  private _projectApi!: AxiosInstance;
  private _projectApiUrl!: string;
  public set projectApiUrl(value: string) {
    this._projectApiUrl = value;
    this._projectApi = axios.create({
      baseURL: this._projectApiUrl,
    });
  }

  public get projectApiUrl(): string {
    return this._projectApiUrl;
  }

  private _masterApi!: AxiosInstance;
  private _masterApiUrl!: string;
  public set masterApiUrl(value: string) {
    this._masterApiUrl = value;
    this._masterApi = axios.create({
      baseURL: this._masterApiUrl,
    });
  }

  public async sendApiRequest<T>(
    p_requestType: ApiRequestType,
    p_endpoint: string,
    p_data: any = null,
    p_scope: ApiRequestScope = ApiRequestScope.PROJECT
  ): Promise<ApiResponse<T>> {
    let api: AxiosInstance;
    if (p_scope === ApiRequestScope.PROJECT) {
      api = this._projectApi;
      await this._checkAuthorization();
    } else {
      api = this._masterApi;
    }

    const response: ApiResponse<T> = { hasSucceeded: false };

    try {
      const requestConfig: AxiosRequestConfig = {
        method: p_requestType,
        url: p_endpoint,
      };
      if (p_requestType === ApiRequestType.GET && p_data) {
        requestConfig.params = p_data;
      } else {
        requestConfig.data = p_data;
      }

      const axiosResponse: AxiosResponse = await api.request(requestConfig);

      response.result = axiosResponse.data;
      response.hasSucceeded = true;
    } catch (e) {
      console.log("ERROR:", e);
      response.error = { message: e.message, isOperational: false, code: 400 };
      if (e.response && e.response.status) {
        response.error.code = e.response.status;
      }
      response.hasSucceeded = false;
    }

    return response;
  }

  private async _checkAuthorization() {
    const authToken: string = authController.getAuthToken();
    if (authToken && this._projectApi) {
      this._projectApi.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    }
  }
}

export default new ApiManager();

export interface ApiResponse<T> {
  hasSucceeded: boolean;
  result?: T;
  error?: ApiError;
}

export interface ApiError {
  message: string;
  code: number;
  isOperational: boolean;
}

export enum ApiRequestType {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
}

export enum ApiRequestScope {
  PROJECT = "PROJECT",
  MASTER = "MASTER",
}
