//export const DEFAULT_MASTER_API_URL = "https://api.staging.audience.direct";
//export const DEFAULT_PROJECT_API_URL = "https://api.staging.audience.direct";
//export const DEFAULT_STORAGE_URL = "https://static.staging.sales-drive.app/storage/nespresso";

export const DEFAULT_MASTER_API_URL = "https://api.staging.sales-drive.app";
export const DEFAULT_PROJECT_API_URL = "https://api.staging.sales-drive.app";
export const DEFAULT_STORAGE_URL = "https://static.staging.sales-drive.app/storage/nespresso";

export enum LocalStorageKey {
  AUDIENCES_META = "AUDIENCES_META",
  COMPANIES_META = "COMPANIES_META",
  CONTENTS_META = "CONTENTS_META",
  ASSET_FOLDERS_META = "ASSET_FOLDERS_META",
  SLIDES_META = "SLIDES_META",
  DATA_PROVIDERS_META = "DATA_PROVIDERS_META",
  AUDIENCE_SESSIONS = "AUDIENCE_SESSIONS",
  AUDIENCE_SESSION_EVENTS = "AUDIENCE_SESSION_EVENTS",
  ERROR_REPORTS = "ERROR_REPORTS",
  DOMAIN_INFO = "DOMAIN_INFO",
  OFFLINE_AVAILABILITY = "OFFLINE_AVAILABILITY",
  USER_SESSION = "USER_SESSION",
  PWA_INSTALLED = "PWA_INSTALLED",
  AUDIENCE_LIST_COLLAPSED = "AUDIENCE_LIST_COLLAPSED",
  GROUP_BY_COMPANY = "GROUP_BY_COMPANY",
  DEEPLINK_PARAMS = "DEEPLINK_PARAMS",
  INTRODUCTION_BOX_SHOWN = "INTRODUCTION_BOX_SHOWN",
  HINTS = "HINTS",
  VERSION = "VERSION",
  GAMIFICATION_LEVEL_INDEX = "GAMIFICATION_LEVEL_INDEX",
  GAMIFICATION_STATUS_INDEX = "GAMIFICATION_STATUS_INDEX",
  GAMIFICATION_INTRODUCTION_BOX_SHOWN = "GAMIFICATION_INTRODUCTION_BOX_SHOWN",
  ROAD_MODE = "ROAD_MODE",
  LIKED_CONTENT = "PWA_LIKED_CONTENT"
}

export enum SessionStorageKey {
  ACCESS_TOKEN = "ACCESS_TOKEN",
  TOKEN_EXPIRATION = "TOKEN_EXPIRATION",
  REFRESH_TOKEN = "REFRESH_TOKEN"
}

export enum DtoType {
  BODY = "BODY",
  META = "META",
  OUTLINE = "OUTLINE",
  CONFIG = "CONFIG",
}

export const APP_URL_STG = "https://outlook-add-in.staging.sales-drive.app";
// export const APP_URL_STG_FEDERATED = "https://outlook-add-in.federated.staging.sales-drive.app";
export const APP_URL_PRD = "https://outlook-add-in.sales-drive.app";
export const APP_URL_PRD_NS_UNIVERSE = "https://outlook-add-in.nespressouniverse.sales-drive.app";
export const APP_URL_PRD_NS_GLOBE = "https://outlook-add-in.nespressoglobe.sales-drive.app";

export enum AppType {
  STG = "STAGING",
  // STG_FEDERATED = "STAGING_FEDERATED",
  PRD = "PRODUCTION",
  PRD_NS_UNIVERSE = "PRODUCTION_NS_UNIVERSE",
  PRD_NS_GLOBE = "PRODUCTION_NS_GLOBE",
}

export interface AppTypeGoogleConfig {
  appType: AppType;
  url: string;
  id: string;
}

export const GTagConfig: AppTypeGoogleConfig[] = [
  {
    appType: AppType.STG,
    url: APP_URL_STG,
    id: process.env.G_TAG_ID_STG
  },
  // [AppType.STG_FEDERATED]: {
  //   url: APP_URL_STG_FEDERATED,
  //   id: process.env.G_TAG_ID_STG_FEDERATED
  // },
  {
    appType: AppType.PRD,
    url: APP_URL_PRD,
    id: process.env.G_TAG_ID_PRD
  },
  {
    appType: AppType.PRD_NS_UNIVERSE,
    url: APP_URL_PRD_NS_UNIVERSE,
    id: process.env.G_TAG_ID_PRD_NS_UNIVERSE
  },
  {
    appType: AppType.PRD_NS_GLOBE,
    url: APP_URL_PRD_NS_GLOBE,
    id: process.env.G_TAG_ID_PRD_NS_GLOBE
  },
]
