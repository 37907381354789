import { IProjectConfigDto, IProjectSessionDto } from "./project.dto";
import Model from "../../__lib/_model/Model";
import { SystemFiles } from "./project.constants";
import { IModelDto } from "../../__lib/_model/model.dto";

class ProjectModel extends Model implements IProjectSessionDto {
  //public analyticsCfg!: IAnalyticsProjectCfgDto;
  public apiUrl: string = "";
  public availableLangCodes: string[] = [];
  public config!: IProjectConfigDto;
  public constructs: string[] = [];
  public contentAppResources: string[] = [];
  public crmProviders: any;
  public dataSchemas: string[] = [];
  public displayName: string = "";
  public identifier: string = "";
  public storageUrl: string = "";
  //public tierConfig!: ITierConfig;
  //public tierQuota!: ITierQuota;
  public expirationDate: Date = new Date();
  public storageTaken: number = -1;

  public systemFolderId: string = "";
  public systemFolder: string = "";
  public logoLightBgUrl: string = "";
  public logoDarkBgUrl: string = "";
  public fontLightUrl: string = "";
  public fontRegularUrl: string = "";
  public fontBoldUrl: string = "";

  public projectLogoFile!: File;

  public storageIsCalculating: boolean = false;

  public mapToDto(): IModelDto {
    return {
      displayName: this.displayName,
    };
  }

  public setupPaths() {
    this.systemFolderId = `asf-${this.identifier}-system-assets`;
    this.systemFolder = `${this.storageUrl}/assetFolders/${this.systemFolderId}`;
    this.refreshPaths();
  }

  public refreshPaths() {
    this.logoLightBgUrl = `${this.systemFolder}/${SystemFiles.LOGO_LIGHT_BG}?t=${new Date().getTime()}`;
    this.logoDarkBgUrl = `${this.systemFolder}/${SystemFiles.LOGO_DARK_BG}?t=${new Date().getTime()}`;
    this.fontLightUrl = `${this.systemFolder}/${SystemFiles.FONT_LIGHT}?t=${new Date().getTime()}`;
    this.fontRegularUrl = `${this.systemFolder}/${SystemFiles.FONT_REGULAR}?t=${new Date().getTime()}`;
    this.fontBoldUrl = `${this.systemFolder}/${SystemFiles.FONT_BOLD}?t=${new Date().getTime()}`;
  }
}

export default ProjectModel;
