import React, {useContext, useEffect} from "react";
import {RoutingIdentifier} from "../../__lib/plugins/router/router.constants";
import {useHistory} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import {AuthContext} from "../../__lib/providers/AuthProvider";

export default function LoginCallbackPage() {
    const history = useHistory();
    const {isValidToken: isAuthenticated} = useContext(AuthContext);

    useEffect(() => {
        if (isAuthenticated) {
            return history.push(RoutingIdentifier.CONTENT_OVERVIEW);
        }
        authenticate();
    }, [isAuthenticated]);

    const authenticate = async () => {
        // get ?code query parameter from url
        const urlParams = new URLSearchParams(window.location.search);
        const code = decodeURIComponent(urlParams.get('code'));

        // send decoded code from Dialog back to Outlook add-in
        if ((module as any).hot && !Office.context.ui) {
            window.opener.postMessage({type: 'auth', value: code}, window.location.origin);
        } else {
            Office.context.ui.messageParent(code);
        }
    }

    return (
      <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
          <span>Please wait...</span>
      </div>
    );
}
