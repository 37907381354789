export enum UserOrigin {
  STATIC = "STATIC",
  FEDERATED = "FEDERATED",
}

export enum UserStatus {
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  MIGRATED = "MIGRATED",
}
