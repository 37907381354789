export enum SystemFiles {
  LOGO_LIGHT_BG = "logo-light-bg.png",
  LOGO_DARK_BG = "logo-dark-bg.png",
  FONT_LIGHT = "light-font.woff2",
  FONT_REGULAR = "regular-font.woff2",
  FONT_BOLD = "bold-font.woff2",
}

export enum Colors {
  PRIMARY = "primary",
  SECUNDARY = "secondary",
  TERTIARY = "tertiary",
}

export enum Fonts {
  LIGHT = "light",
  REGULAR = "regular",
  BOLD = "bold",
}

export const FONT_NAME_PREFIX = "audience-";
