import OutlookAppModel from "../../_model/OutlookAppModel";
import {CognitoAuth} from "amazon-cognito-auth-ts/lib";
import apiManager, {ApiRequestType, ApiResponse} from "../../_controller/ApiManager";
import {IUpdateResultDto} from "../../entity/_model/entity.dto";
import CognitoAuthSession from "amazon-cognito-auth-ts/lib/CognitoAuthSession";
import axios from "axios";
import {SessionStorageKey} from "../../_model/app.constants";

export interface ILoginUserParseResponse {
    accessToken: string;
    refreshToken: string;
    expiration: number;
}

class AuthController {
    private _getCognito(): CognitoAuth {
        return OutlookAppModel.getInstance().cognito;
    }

    public getAuthToken(): string {
        return JSON.parse(sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN));
    }

    public async logout() {
        this._getCognito().signOut();
    }

    public async loginUserParseResponse(callbackCode: string): Promise<ILoginUserParseResponse | null> {
        try {
            const currentUrl = window.location.origin;
            const res = await axios.post(
              `https://${this._getCognito().getAppWebDomain()}/oauth2/token?grant_type=authorization_code&code=${callbackCode}&client_id=${this._getCognito().getClientId()}&redirect_uri=${currentUrl}/login-callback`,
              {},
              {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            );

            if (res.data && res.status === 200 && 'access_token' in res.data) {
                const isVerified = await this.verifyFederated(res.data.access_token);
                if (!isVerified) return null;

                // save access token to be used as bearer for client api calls
                // expiration time is returned in seconds, so we need to convert it to milliseconds
                return {
                    accessToken: res.data.access_token,
                    refreshToken: res.data.refresh_token,
                    expiration: new Date().getTime() + (res.data.expires_in * 1000)
                };
            }
            return null;
        } catch (e) {
            return null;
        }
    }

    public async verifyFederated(accessToken: string): Promise<boolean> {
        try {
            //check if user is federated, and then try to verify him/her (update the sub by crosschecking with email)
            if (this._getCognito().identityProvider && this._getCognito().identityProvider.length > 0) {
                //try to verify him/her with access token
                const response: ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(
                    ApiRequestType.PUT,
                    `/client-api/users/sso/`,
                    { accessToken }
                );
                return response.hasSucceeded;
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    public async refreshSession(refreshToken: string): Promise<ILoginUserParseResponse | null> {
        console.log('AuthController :: refreshing token...');
        try {
            const newSession: CognitoAuthSession = await this._getCognito().refreshSession(refreshToken);
            return {
                accessToken: newSession.accessToken.getJwtToken(),
                refreshToken: refreshToken,
                expiration: newSession.accessToken.getExpiration() * 1000
            };
        } catch (e) {
            console.log('AuthController :: error refreshing token');
            //await this._forceLoginUser();
            //todo: force login
            return null;
        }
    }
}

export default new AuthController();
