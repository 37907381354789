import "./__assets/styles/index.scss";

import * as React from "react";
import AuthPage from "./auth/_view/AuthPage";
import LoginCallbackPage from "./auth/_view/LoginCallbackPage";
import { Switch, Route } from "react-router-dom";
import {RoutingIdentifier} from "./__lib/plugins/router/router.constants";
import {Suspense} from "react";
import {Spinner} from "react-bootstrap";
import ContentOverviewPage from "./content/_view/ContentOverviewPage";

const PrivateRoute = React.lazy(() => import("./__lib/plugins/router/PrivateRoute"));

export default function App() {
  return (
    <Switch>
      <Route exact path={RoutingIdentifier.START} component={AuthPage} />
      <Route exact path={RoutingIdentifier.LOGIN_CALLBACK} component={LoginCallbackPage} />
      <Route exact path={RoutingIdentifier.UNAUTHORIZED} component={AuthPage} />

      {/* Private Routes */}
      <Suspense fallback={<div className="d-flex justify-content-center align-items-center w-100 h-100"><Spinner/></div>}>
        <PrivateRoute exact path={RoutingIdentifier.CONTENT_OVERVIEW} component={ContentOverviewPage}/>
      </Suspense>
    </Switch>
  );
}
