export default class LocalStorageManager {
  public static storeValue(p_key: string, p_value: string): boolean {
    try {
      window.localStorage.setItem(p_key, p_value);
      return true;
    } catch (e) {
      console.log("couldn't store localStorage value:", p_value);
      return false;
    }
  }

  public static retrieveValue(p_key: string): string | null {
    try {
      return window.localStorage.getItem(p_key);
    } catch (e) {
      console.log("couldn't retrieve localStorage key:", p_key);
      return null;
    }
  }

  public static deleteValue(p_key: string): boolean {
    try {
      window.localStorage.removeItem(p_key);
      return true;
    } catch (e) {
      console.log("couldn't delete localStorage key:", p_key);
      return false;
    }
  }

  public static clearAll(): boolean {
    try {
      window.localStorage.clear();
      return true;
    } catch (e) {
      console.log("couldn't clear localStorage ");
      return false;
    }
  }
}
