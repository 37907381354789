import * as React from "react";
import { Image } from "@fluentui/react-components";
import logo from "../../../__assets/img/logo.png";
import previewContent from "../../../__assets/img/preview-content.png";
import styles from "./Header.module.scss";
import {useTranslation} from "react-i18next";

export default function Header() {
  const {t} = useTranslation();

  return (
    <section className={styles.header}>
      <Image width="120" height="35" src={logo} />
      <Image src={previewContent} style={{ width: '100%', margin: '1rem' }} />

      <div className="px-3 text-center">
        <p className={styles.title}>
          {t('HeaderTitle')}
        </p>
        <p>{t('HeaderSubtitle')}</p>
      </div>
    </section>
  );
}
