import apiManager, { ApiRequestType, ApiResponse } from "../../_controller/ApiManager";
import { IAppUserSessionDto } from "../../_model/app.dto";
import LocalStorageManager from "../../__lib/offline_storage/LocalStorageManager";
import { LocalStorageKey } from "../../_model/app.constants";
import UserModel from "../_model/UserModel";
import OutlookAppModel from "../../_model/OutlookAppModel";
import languageManager from "../../__lib/language_manager/LanguageManager";

class UserController {
  private readonly _appModel: OutlookAppModel = OutlookAppModel.getInstance();
  private readonly _userModel: UserModel = UserModel.getInstance();

  private parseStorageUrls(appUserSessionDto: IAppUserSessionDto): IAppUserSessionDto {
    const appUrl: URL = new URL(window.location.href);
    if (appUrl.host.includes("localhost:8080")) {
      return appUserSessionDto;
    }

    const globalStorageUrl: URL = new URL(appUserSessionDto.global.storageUrl);
    appUserSessionDto.global.storageUrl = globalStorageUrl.origin + globalStorageUrl.pathname;

    const projectStorageUrl: URL = new URL(appUserSessionDto.project.storageUrl);
    // todo: appUrl.origin?
    appUserSessionDto.project.storageUrl = projectStorageUrl.origin + projectStorageUrl.pathname;

    this._appModel.storageUrl = appUserSessionDto.project.storageUrl;

    return appUserSessionDto;
  }

  public async startRemoteAppUserSession(): Promise<boolean> {
    const createSessionDto: any = {
      appVersion: "2.0.0",
      deviceIdentifier: "",
      runtime: "UNKNOWN"
    };

    const response: ApiResponse<IAppUserSessionDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/users/sessions`, createSessionDto);
    if (response.hasSucceeded) {
      let appUserSessionDto: IAppUserSessionDto = response.result as IAppUserSessionDto;

      // set user's language
      languageManager.availableLangCodes = appUserSessionDto.availableLanguages;
      languageManager.language = appUserSessionDto.user.langCode;

      // parse storage urls
      appUserSessionDto = this.parseStorageUrls(appUserSessionDto);
      LocalStorageManager.storeValue(LocalStorageKey.USER_SESSION, JSON.stringify(appUserSessionDto));
      return this.setAppUserSession();
    }

    return false;
  }

  public setAppUserSession(p_appUserSessionDto?: IAppUserSessionDto) {
    const appUserSessionDto: IAppUserSessionDto | null = p_appUserSessionDto ? p_appUserSessionDto : JSON.parse(LocalStorageManager.retrieveValue(LocalStorageKey.USER_SESSION) as string);

    if (appUserSessionDto) {
      this._userModel.mapFromDto(appUserSessionDto.user);

      this._userModel.sessionID = appUserSessionDto.sessionID;
      this._userModel.rights = appUserSessionDto.rights;

      this._userModel.project.mapFromDto(appUserSessionDto.project);
      this._userModel.project.setupPaths();

      return true;
    } else {
      return false;
    }
  }
}

export default new UserController();
