import { ISplashDto } from "./app.dto";
import AuthModel from "../auth/_model/AuthModel";
import {CognitoAuth} from "amazon-cognito-auth-ts/lib";
import {AppModel} from "@sales-drive/audience-library/lib/app/_model/AppModel";
import {DEFAULT_STORAGE_URL} from "./app.constants";
import AudienceListModel from "../audience/_model/AudienceListModel";
import ContentListModel from "../content/_model/ContentListModel";

class OutlookAppModel extends AppModel {
  private static _instance: OutlookAppModel;

  public storageUrl: string = process.env.STORAGE_URL || DEFAULT_STORAGE_URL;

  public splash: ISplashDto = {
    bgUrl: "img/init/init-bg.jpg",
    logoUrl: "img/init/init-logo.png"
  }

  public auth: AuthModel = new AuthModel();
  public get cognito(): CognitoAuth {
    return this.auth.cognito;
  }

  public audiences: AudienceListModel = new AudienceListModel();
  public content: ContentListModel = new ContentListModel();

  private constructor() {
    super();
  }

  public static getInstance(): OutlookAppModel {
    if (!this._instance) {
      this._instance = new OutlookAppModel();
    }

    return this._instance;
  }
}

export default OutlookAppModel;
