import * as React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import {FluentProvider, webLightTheme} from "@fluentui/react-components";
import appController from "./_controller/AppController";
import {BrowserRouter} from "react-router-dom";
import AuthProvider from "./__lib/providers/AuthProvider";
import "./__lib/plugins/i18next/index";

const rootElement: HTMLElement = document.getElementById("root");
const root = createRoot(rootElement);

const app = (
  <FluentProvider theme={webLightTheme}>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </FluentProvider>
);

/* Render application after Office initializes */
Office.onReady(async () => {
  await appController.setAppEnvironment();
  await appController.fetchDomainInfo();

  root.render(app);
});

/* Webpack Hot Module Replacement API for local development */
if ((module as any).hot) {
  // dependencies: ./App.tsx for hot reloading the callback
  (module as any).hot.accept("./App", async () => {
    await appController.setAppEnvironment();
    await appController.fetchDomainInfo();

    root.render(app);
  });
}
