import apiManager, {ApiRequestScope, ApiRequestType, ApiResponse} from "../_controller/ApiManager";
import {IDomainInfoDto} from "../_model/app.dto";
import OutlookAppModel from "../_model/OutlookAppModel";
import LocalStorageManager from "../__lib/offline_storage/LocalStorageManager";
import {DEFAULT_MASTER_API_URL, DEFAULT_PROJECT_API_URL, LocalStorageKey} from "../_model/app.constants";
import {AppMode} from "@sales-drive/audience-library/lib/app/_model/app.constants";

class AppController {
  private _appModel: OutlookAppModel = OutlookAppModel.getInstance();

  public async setAppEnvironment(): Promise<boolean> {
    apiManager.masterApiUrl = process.env.MASTER_API_URL || DEFAULT_MASTER_API_URL;
    apiManager.projectApiUrl = process.env.PROJECT_API_URL || DEFAULT_PROJECT_API_URL;

    this._appModel.mode = AppMode[process.env.NODE_ENV];

    return this.setDomainInfoFromStorage();
  }

  public async fetchDomainInfo(): Promise<boolean> {
    const appDomain: string = new URL(window.location.href).host;
    const response: ApiResponse<IDomainInfoDto> = await apiManager.sendApiRequest(
      ApiRequestType.GET,
      `/master-api/domains/${appDomain}`,
      null,
      ApiRequestScope.MASTER
    );

    if (response.hasSucceeded) {
      LocalStorageManager.storeValue(LocalStorageKey.DOMAIN_INFO, JSON.stringify(response.result));
    }

    return this.setDomainInfoFromStorage();
  }

  public setDomainInfoFromStorage() {
    const domainInfo: IDomainInfoDto | null = JSON.parse(
      LocalStorageManager.retrieveValue(LocalStorageKey.DOMAIN_INFO) as string
    );

    if (domainInfo) {
      apiManager.projectApiUrl = domainInfo.projectApiUrl;
      if (domainInfo.splash) {
        if (domainInfo.splash.bgUrl) {
          this._appModel.splash.bgUrl = domainInfo.splash.bgUrl;
        }
        if (domainInfo.splash.logoUrl) {
          this._appModel.splash.logoUrl = domainInfo.splash.logoUrl;
        }
      }
      return true;
    } else {
      return false;
    }
  }
}

export default new AppController();
