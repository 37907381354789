import {EntityType, IEntityTypeConfig, LoadingStatus} from "../_model/entity.constants";
import apiManager, {ApiRequestType, ApiResponse} from "../../_controller/ApiManager";
import {IEntityBodyDto, IUpdateResultDto} from "../_model/entity.dto";
import EntityModel from "../_model/EntityModel";

abstract class EntityController {
  protected _entityTypeConfig: IEntityTypeConfig;

  constructor(entityTypeConfig: IEntityTypeConfig) {
    this._entityTypeConfig = entityTypeConfig;
  }

  public async fetchBody(entity: EntityModel): Promise<boolean> {
    entity.loadingStatus = LoadingStatus.LOADING_BODY;

    const response: ApiResponse<IUpdateResultDto> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${this._getEntityConfig(entity.entityType).apiPath}/${entity.ID}`);
    if (response.hasSucceeded) {
      const bodyDto: IEntityBodyDto = response.result as IEntityBodyDto;
      if (entity.loadingStatus < LoadingStatus.BODY_LOADED || bodyDto.version >= entity.version) {
        entity.mapFromDto(bodyDto);
        entity.loadingStatus = LoadingStatus.BODY_LOADED;
      }
    } else {
      entity.loadingStatus = LoadingStatus.BODY_ERROR;
    }
    return response.hasSucceeded;
  }

  //can be overridden (eg when you have to switch between the 3 types of content entities)
  protected _getEntityConfig(_entityType: EntityType): IEntityTypeConfig {
    return this._entityTypeConfig;
  }
}

//Singleton export
export default EntityController;
