import React, {useEffect} from "react";
import {createContext, PropsWithChildren, useState} from "react";
import {SessionStorageKey} from "../../_model/app.constants";
import {useSessionStorage} from "usehooks-ts";
import authController from "../../auth/_controller/AuthController";

export interface IAuthContext {
  accessToken: string;
  setAccessToken: (token: any) => void;
  refreshToken: string;
  setRefreshToken: (token: any) => void;
  tokenExpiration: string;
  setTokenExpiration: (expiration: any) => void;
  isValidToken: boolean;
  signOut: () => void;
}

const ctx = createContext<IAuthContext|null>(null);
export const AuthContext = ctx;

export default function AuthProvider({ children }: PropsWithChildren) {
  const initialAccessToken = sessionStorage.getItem(SessionStorageKey.ACCESS_TOKEN);
  const initialRefreshToken = sessionStorage.getItem(SessionStorageKey.REFRESH_TOKEN);
  const initialTokenExpiration = sessionStorage.getItem(SessionStorageKey.TOKEN_EXPIRATION);

  const [accessToken, setAccessToken] = useSessionStorage(SessionStorageKey.ACCESS_TOKEN, initialAccessToken);
  const [refreshToken, setRefreshToken] = useSessionStorage(SessionStorageKey.REFRESH_TOKEN, initialRefreshToken);
  const [tokenExpiration, setTokenExpiration] = useSessionStorage(SessionStorageKey.TOKEN_EXPIRATION, initialTokenExpiration);

  const [isValidToken, setIsValidToken] = useState(false);

  useEffect(() => {
    try {
      // check if the tokenExpiration is a valid unix timestamp
      if (isNaN(Number.parseInt(tokenExpiration))) {
        setIsValidToken(false);
        return;
      }

      // check if the tokenExpiration (in ms) is less than the current time
      if (Number.parseInt(tokenExpiration) < Date.now()) {
        setIsValidToken(false);

        authController.refreshSession(refreshToken).then((res) => {
          if (!res) return;

          if (Office?.context?.roamingSettings) {
            Office.context.roamingSettings.set('accessToken', res.accessToken);
            Office.context.roamingSettings.set('expiration', res.expiration.toString());
            Office.context.roamingSettings.saveAsync();
          }

          setAccessToken(res.accessToken);
          setTokenExpiration(res.expiration.toString());
        });

        return;
      }

      // check if the token is set
      setIsValidToken(!!accessToken);
    } catch (error) {
      console.log('token validation error', error);
      setIsValidToken(false);
    }
  }, [accessToken, refreshToken, tokenExpiration]);

  const signOut = () => {
    if (Office?.context?.roamingSettings) {
      Office.context.roamingSettings.remove('accessToken');
      Office.context.roamingSettings.remove('refreshToken');
      Office.context.roamingSettings.remove('expiration');
      Office.context.roamingSettings.saveAsync();
    }

    setAccessToken('');
    setRefreshToken('');
    setTokenExpiration('');
  }

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        setAccessToken: (t: any) => setAccessToken(t.toString()),
        refreshToken,
        setRefreshToken: (t: any) => setRefreshToken(t.toString()),
        tokenExpiration,
        setTokenExpiration: (e: any) => setTokenExpiration(e.toString()),
        isValidToken,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}




//const cognito = OutlookAppModel.getInstance().cognito;
//const jwksUri = `https://cognito-idp.eu-west-1.amazonaws.com/${cognito.userPoolId}/.well-known/jwks.json`;
//const jwks = await JWK.asKeyStore((await fetch(jwksUri)).json());
// Verify the token using the JWKS
//const verifiedToken = await JWKS.verify(accessToken, jwks);
