import {DeltaStatus, EntityType, LoadingStatus} from "./entity.constants";
import Model from "../../__lib/_model/Model";
import {IEntityBodyDto, IEntityMetaDto} from "./entity.dto";
import {DtoType} from "../../_model/app.constants";
import {IModelDto} from "../../__lib/_model/model.dto";

export default abstract class EntityModel extends Model {
  public ID: string = '';
  public version: number = 1;
  public identifier?: string;
  public parentEntity?: EntityModel; //eg a DataProviderModel can have an AudienceModel as parent (for it's customProperties)

  //status
  public loadingStatus: LoadingStatus = LoadingStatus.ID_ONLY;
  public deltaStatus: DeltaStatus = DeltaStatus.UNCHANGED;
  public entityType!: EntityType;

  //meta
  public createdByUserID: number = 0;
  public createdDate: Date = new Date();
  public updatedDate: Date = new Date();
  public latestActivityDate: Date = new Date();
  public createdByUserName: string = '';
  public folderID?: number;

  constructor(parentEntity?: EntityModel) {
    super();
    if (parentEntity) {
      this.parentEntity = parentEntity;
    }
  }

  public mapFromDto(dto: IModelDto) {
    super.mapFromDto(dto);
  }

  public mapToDto(dtoType: DtoType): IModelDto {
    if (dtoType === DtoType.BODY) {
      const dto: IEntityBodyDto = {
        ID: this.ID,
        version: this.version
      };

      if (this.identifier) {
        dto.identifier = this.identifier;
      }

      return dto;
    } else if (dtoType === DtoType.META) {
      return {
        ID: this.ID,
        version: this.version,
        entityType: this.entityType,
        createdByUserID: this.createdByUserID,
        createdDate: this.createdDate,
        updatedDate: this.updatedDate,
        latestActivityDate: this.latestActivityDate,
        createdByUserName: this.createdByUserName,
        folderID: this.folderID,
      } satisfies IEntityMetaDto;
    }

    console.log("no mapping found for " + dtoType);
    return {};
  }
}
