import React, {useState} from "react";
import ContentModel from "../../_model/ContentModel";
import styles from "./ContentOverviewItem.module.scss";
import classNames from "classnames";
import AaImage from "../../../__lib/_view/AaImage";
import LinesEllipsis from "react-lines-ellipsis";
import {EntityType, LoadingStatus} from "../../../entity/_model/entity.constants";
import Util from "../../../__lib/util/Util";
import {Badge, Button, Spinner} from "react-bootstrap";
import {FILE_TYPE_CONFIG} from "../../file/_model/content-file.constants";
import ContentFileModel from "../../file/_model/ContentFileModel";
import {getMlValue} from "../../../__lib/language_manager/LanguageManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

interface ComponentProps {
  parentReference: string;
  content: ContentModel;
  isCreatingShare: boolean;
  onClick: (content: ContentModel) => void;
}

export default function ContentOverviewItem({ parentReference, content, isCreatingShare, onClick }: ComponentProps) {
  const {t} = useTranslation();
  const [posterLoaded, setPostedLoaded] = useState(false);

  const contentTypeId = () => {
    if (content.entityType === EntityType.CONTENT_FILE) {
      return FILE_TYPE_CONFIG[(content as ContentFileModel).fileType].identifier.toLowerCase();
    }
    return content.entityType.toLowerCase();
  }

  const contentTypeClasses = () => {
    if (content.entityType === EntityType.CONTENT_FILE) {
      return classNames(styles['file-type'], styles[contentTypeId()]);
    }
    return classNames(styles['type'], styles[contentTypeId()]);
  }

  return (
    <>
      <div
        id={`overview-${parentReference}-${content.ID}`}
        className={classNames(
          styles["content-item"],
          "shadow-lg",
          {
            "body-loaded": content.loadingStatus >= LoadingStatus.BODY_LOADED,
            "poster-loaded": posterLoaded
          }
        )}
        onClick={() => onClick(content)}
      >
        <div className={styles.wrapper}>
          <div className={classNames(styles["hover-overlay"], {"visible": isCreatingShare})}>
            {isCreatingShare ? (
              <Spinner className={styles.spinner} />
            ): (
              <Button className={styles["hover-btn"]}>
                <FontAwesomeIcon icon={faShare}/>
                <span>{t('ShareButton')}</span>
              </Button>
            )}
          </div>

          <div className={styles.background}>
            {content && content.loadingStatus === LoadingStatus.BODY_LOADED && (
              <AaImage
                className={styles.image}
                imageUrl={Util.getAaImgUrl(getMlValue(content.poster))}
                loadedCallbackFn={() => setPostedLoaded(true)}
                imageAlt="poster"
              />
            )}
            <div className={styles.overlay}></div>
          </div>

          <div className={classNames(styles.content, "d-flex flex-column justify-content-between align-items-stretch")}>
            <div className="d-flex justify-content-between pe-4">
              <Badge className={contentTypeClasses()}>
                {content.entityType}
              </Badge>
            </div>

            <LinesEllipsis
              className={styles.name}
              text={getMlValue(content.name)}
              maxLine="2"
            />
          </div>
          <div className={styles["popover-position"]}></div>
        </div>
      </div>
    </>
  );
}