import EntityModel from "./EntityModel";
import {ENTITY_CONFIG, EntityType, IEntityTypeConfig} from "./entity.constants";
import ListModel from "@sales-drive/audience-library/lib/_model/ListModel";

abstract class EntityListModel<T extends EntityModel> extends ListModel<T> {
  //used to check if we should flag entities as new
  public noMetaCache: boolean = true;

  public getEntityByID(id: string): T|null {
    for (let i = 0; i < this.getSize; i += 1) {
      const entity = this.get(i);
      if (entity.ID === id) {
        return entity;
      }
    }
    return null;
  }
}

export default EntityListModel;
