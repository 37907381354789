import {ENTITY_CONFIG, EntityType, IEntityTypeConfig} from "../../entity/_model/entity.constants";
import EntityController from "../../entity/_controller/EntityController";
import apiManager, {ApiRequestType, ApiResponse} from "../../_controller/ApiManager";
import {ICreateShareResultDto, IEntityAudienceShareDto} from "../../entity/_model/entity.dto";
import ContentModel from "../_model/ContentModel";

class ContentController extends EntityController {
    protected _getEntityConfig(p_entityType:EntityType):IEntityTypeConfig {
        //return the subentities apiPath, not the apiPath of the controller (eg "PRESENTATION" vs "CONTENT")
        return ENTITY_CONFIG[p_entityType];
    }

    public async shareContentWithAudience(content: ContentModel, shareDto: IEntityAudienceShareDto): Promise<ApiResponse<ICreateShareResultDto>> {
        const endPoint = `/client-api/${this._getEntityConfig(content.entityType).apiPath}/${content.ID}/audience-share`;
        return await apiManager.sendApiRequest(ApiRequestType.POST, endPoint, shareDto);
    }
}

//Singleton export
export default new ContentController(ENTITY_CONFIG.CONTENT);
