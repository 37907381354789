import {CognitoAuth} from "amazon-cognito-auth-ts";
import {IDomainInfoDto} from "../../_model/app.dto";
import LocalStorageManager from "../../__lib/offline_storage/LocalStorageManager";
import {LocalStorageKey} from "../../_model/app.constants";
import {CognitoAuthOptions} from "amazon-cognito-auth-ts/lib/CognitoAuth";

class AuthModel {
    public dialog!: Office.Dialog | WindowProxy;

    private _cognito!: CognitoAuth;
    public get cognito(): CognitoAuth {
        if (!this._cognito) {
            const domainInfo: IDomainInfoDto | null = JSON.parse(LocalStorageManager.retrieveValue(LocalStorageKey.DOMAIN_INFO));
            const protocol: string = new URL(window.location.href).protocol;

            if (domainInfo) {
                const authOptions: CognitoAuthOptions = {
                    ClientId: domainInfo.cognitoClientId,
                    AppWebDomain: domainInfo.cognitoLoginDomain,
                    TokenScopesArray: ["openid", "email"],
                    RedirectUriSignIn: `${protocol}//${domainInfo.domain}/login-callback.html`,
                    RedirectUriSignOut: `${protocol}//${domainInfo.domain}/logout.html`,
                    IdentityProvider: domainInfo.cognitoProvider,
                    UserPoolId: domainInfo.cognitoUserPoolId,
                    Storage: null,
                };
                this._cognito = new CognitoAuth(authOptions, false);
            } else {
                console.log("Couldn't read domain info from local storage");
            }
        }
        return this._cognito;
    }
}

export default AuthModel;
