import apiManager, {ApiRequestType, ApiResponse} from "../../_controller/ApiManager";
import {LoadingStatus, DeltaStatus, IEntityTypeConfig} from "../_model/entity.constants";
import EntityListModel from "../_model/EntityListModel";
import EntityModel from "../_model/EntityModel";
import {ICreateResultDto, IEntityBodyDto, IEntityMetaDto} from "../_model/entity.dto";
import EntityController from "./EntityController";
import { DtoType } from "../../_model/app.constants";

abstract class EntityListController<T extends EntityModel> {
  protected _entityTypeConfig: IEntityTypeConfig;
  protected _entityListModel: EntityListModel<T>;
  protected _entityController: EntityController;

  protected constructor(entityTypeConfig: IEntityTypeConfig, entityListModel: EntityListModel<T>, entityController: EntityController) {
    this._entityTypeConfig = entityTypeConfig;
    this._entityListModel = entityListModel;
    this._entityController = entityController;
  }

  public async fetchAllEntities() {
    //get new meta info from server
    const response: ApiResponse<IEntityMetaDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${this._entityTypeConfig.apiPath}?dto=META`);

    if (response.hasSucceeded) {
      //first loop over all new meta info and flag new/updated deltas
      //check existence by ID and need for update flag by version
      //map the new meta onto the existing models (will content overview layout change according to ?)

      const entityMetaDtos: IEntityMetaDto[] = response.result as IEntityMetaDto[];
      const entityIDs: string[] = [];
      for (let i = 0; i < entityMetaDtos.length; i += 1) {
        const metaDto: IEntityMetaDto = entityMetaDtos[i];
        const entityInMemory: EntityModel|null = this._entityListModel.getEntityByID(metaDto.ID);
        entityIDs.push(metaDto.ID);

        if (entityInMemory) {
          if (entityInMemory.version < metaDto.version) {
            entityInMemory.deltaStatus = DeltaStatus.UPDATED;
          }
          entityInMemory.mapFromDto(metaDto); //update the new meta info
        } else {
          const entity: T = this._newEntity(metaDto) as T;
          // check if it is the device's first time before setting NEW, otherwise all entities are flagged new
          if (!this._entityListModel.noMetaCache) {
            entity.deltaStatus = DeltaStatus.NEW;
          }

          this._entityListModel.add(entity);
        }
      }

      // deletes
      for (let i = this._entityListModel.getSize - 1; i >= 0; i--) {
        const entity: EntityModel = this._entityListModel.get(i);
        if (entityIDs.indexOf(entity.ID) === -1) {
          this._entityListModel.removeByKey('ID', entity.ID);
        }
      }
    }

    this.organizeEntities();

    //fetch them all at once tryout
    await Promise.all(
      this._entityListModel.getAll.map(async(entity) => {
        if (entity.loadingStatus < LoadingStatus.BODY_LOADED || entity.deltaStatus > DeltaStatus.UNCHANGED) {
          await this._entityController.fetchBody(entity);
        }
      })
    );
  }

  //should be overridden when needed (e.g. for content to put them into folders)
  public organizeEntities() {}

  protected _newEntity(_dto: IEntityMetaDto): T {
    return {} as T;
  }

  //can be overridden (e.g. when you have to switch between the 3 types of content entities)
  protected _getEntityConfig(_entity: EntityModel): IEntityTypeConfig {
    return this._entityTypeConfig;
  }

  public async createEntity(entity: T): Promise<boolean> {
    const bodyDto: IEntityBodyDto = entity.mapToDto(DtoType.BODY) as IEntityBodyDto;
    const response:ApiResponse<ICreateResultDto> = await apiManager.sendApiRequest(ApiRequestType.POST, `/client-api/${this._getEntityConfig(entity).apiPath}`, bodyDto);

    if (response.hasSucceeded) {
      entity.ID = (response.result as ICreateResultDto).ID;

      const entityMeta: IEntityMetaDto =  response.result!.meta;
      entity.mapFromDto(entityMeta);
      entity.loadingStatus = LoadingStatus.BODY_LOADED;

      this._entityListModel.add(entity);
    }

    return response.hasSucceeded;
  }
}

export default EntityListController;
