import EntityListController from "../../entity/_controller/EntityListController";
import {DeltaStatus, ENTITY_CONFIG, LoadingStatus} from "../../entity/_model/entity.constants";
import AudienceModel from "../_model/AudienceModel";
import audienceController from "./AudienceController";
import OutlookAppModel from "../../_model/OutlookAppModel";
import apiManager, {ApiRequestType, ApiResponse} from "../../_controller/ApiManager";
import {IAudienceBodyDto, IAudienceMetaDto} from "../_model/audience.dto";
import LocalStorageManager from "../../__lib/offline_storage/LocalStorageManager";

class AudienceListController extends EntityListController<AudienceModel> {
  private _audiencesMeta: IAudienceMetaDto[] = [];

  constructor() {
    super(ENTITY_CONFIG.AUDIENCE, OutlookAppModel.getInstance().audiences, audienceController)
  }

  public async fetchAllEntitiesMeta() {
    const metaResponse: ApiResponse<IAudienceBodyDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${this._entityTypeConfig.apiPath}?dto=META`);
    if (metaResponse.hasSucceeded) {
      this._audiencesMeta = metaResponse.result || [];
      return;
    }

    // todo error handling
    throw new Error('Failed to fetch audience meta data');
  }

  public async fetchAllEntities() {
    //get new outline info from server
    const response: ApiResponse<IAudienceBodyDto[]> = await apiManager.sendApiRequest(ApiRequestType.GET, `/client-api/${this._entityTypeConfig.apiPath}?dto=OUTLINE`);

    if (response.hasSucceeded) {
      const entityMetaDtos: IAudienceBodyDto[] = response.result as IAudienceBodyDto[];
      const entityIDs: string[] = [];
      for (let i = 0; i < entityMetaDtos.length; i += 1) {
        const audienceDto: IAudienceBodyDto = entityMetaDtos[i];
        const entityInMemory: AudienceModel|null = this._entityListModel.getEntityByID(audienceDto.ID);
        entityIDs.push(audienceDto.ID);

        if (entityInMemory) {
          if (entityInMemory.version < audienceDto.version) {
            entityInMemory.deltaStatus = DeltaStatus.UPDATED;
          }
          entityInMemory.mapFromDto(audienceDto);
        } else {
          const newModel = new AudienceModel();
          newModel.mapFromDto(audienceDto);
          if (!this._entityListModel.noMetaCache) {
            newModel.deltaStatus = DeltaStatus.NEW;
          }

          this._entityListModel.add(newModel);
        }
      }

      // deletes
      for (let i = this._entityListModel.getSize - 1; i >= 0; i--) {
        const entity: AudienceModel = this._entityListModel.get(i);
        if (entityIDs.indexOf(entity.ID) === -1) {
          this._entityListModel.removeByKey('ID', entity.ID);
        }
      }
    }

    this._entityListModel.getAll.forEach((entity) => {
      // set portalToken from audience meta
      const meta = this._audiencesMeta.find((m) => m.ID === entity.ID);
      if (meta) {
        entity.portalToken = meta.portalToken;
      }
    });
  }
}

export default new AudienceListController();
